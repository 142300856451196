import React, { Fragment } from "react";
import ReactDOM from 'react-dom';
import { Route, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { createGlobalStyle } from 'styled-components';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { unregister as unregisterSW } from './serviceWorker';

import Application from './Application';
import Draw from "./Draw";

import PhotoBooth from "./PhotoBooth";


firebase.initializeApp({
  apiKey: 'AIzaSyCInpVqILYLRik9-OmMjn6alenXgshCWF0',
  authDomain: 'heapcon-game.firebaseapp.com',
  databaseURL: 'https://heapcon-game.firebaseio.com',
  projectId: 'heapcon-game',
  storageBucket: 'heapcon-game.appspot.com',
  messagingSenderId: '172124657733',
  timestampsInSnapshots: true,
});

firebase.firestore().settings({
  timestampsInSnapshots: true,
});

const GlobalStyle = createGlobalStyle`
  html,
  body {
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: 'Rubik', sans-serif;
    -webkit-font-smoothing: antialiased;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-weight: inherit;
  }
  
  #root {
    width: 100%;
    height: 100%;
    
    display: grid;
    align-items: stretch;
    justify-items: stretch;
    
    > * {
      display: grid;
      grid-row: 1 / span 1;
      grid-column: 1 / span 1;
    }
  }
`;

const Main = () => (
  <Fragment>
    <Application />
    <GlobalStyle />
  </Fragment>
);

const Lottery = () => (
  <Fragment>
    <Draw />
    <GlobalStyle />
  </Fragment>
);

ReactDOM.render(
  <Router history={createBrowserHistory()}>
    <Switch>
      <Route exact path="/draw" component={Lottery} />
      <Route exact path="/share" component={PhotoBooth} />
      <Route component={Main} />
    </Switch>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
unregisterSW();
