import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  .cls-1{fill:#e84005;}.cls-2{fill:#ff4800;}.cls-3{opacity:0.11;}.cls-4{fill:#fff;}.cls-5{fill:#edaa03;}.cls-6{fill:#ffbc00;}.cls-7{fill:#ffc543;}.cls-8{fill:#03898c;}.cls-9{fill:#aa2902;}.cls-10{fill:#00aab7;}.cls-11{fill:#3a3429;}
`;

export default ({ hostRef, innerRef, ...props }) => (
  <div ref={hostRef} {...props}>
    <GlobalStyles />
    <svg viewBox="0 0 812 740">
      <path className="cls-5"
            d="M984.89,302.6a74.33,74.33,0,0,1,12.64-104.36l-40.59-51.77a43.07,43.07,0,0,1-60.48-7.33L339.75,575.56A43.07,43.07,0,0,1,332.42,636l1.71,2.17L506.27,857.8a43.07,43.07,0,0,1,60.48,7.33l556.71-436.42a43.06,43.06,0,0,1,7.33-60.47l-41.55-53A74.32,74.32,0,0,1,984.89,302.6Z"
            transform="translate(-321.77 -127.68)"/>
      <path className="cls-6"
            d="M974.23,291.14a74.35,74.35,0,0,1,12.64-104.36L946.29,135a43.09,43.09,0,0,1-60.48-7.33L329.1,564.09a43.07,43.07,0,0,1-7.33,60.48l1.71,2.18L495.62,846.34a43.07,43.07,0,0,1,60.47,7.32l556.72-436.41a43.08,43.08,0,0,1,7.32-60.48l-41.54-53A74.35,74.35,0,0,1,974.23,291.14Z"
            transform="translate(-321.77 -127.68)"/>
      <g className="cls-3">
        <polygon className="cls-4" points="292.06 680.72 330.49 183.08 499.29 50.76 475.14 537.2 292.06 680.72"/>
      </g>
      <circle className="cls-4" cx="90.46" cy="412.77" r="11.82"/>
      <circle className="cls-4" cx="118.3" cy="448.29" r="11.82"/>
      <circle className="cls-4" cx="146.13" cy="483.8" r="11.82"/>
      <circle className="cls-4" cx="173.97" cy="519.31" r="11.82"/>
      <circle className="cls-4" cx="201.81" cy="554.82" r="11.82"/>
      <circle className="cls-4" cx="229.65" cy="590.33" r="11.82"/>
      <circle className="cls-4" cx="257.48" cy="625.84" r="11.82"/>
      <circle className="cls-4" cx="280.5" cy="655.21" r="11.82"/>
      <rect className="cls-8" x="655.31" y="399.48" width="183.35" height="183.35" rx="34.42" ry="34.42"
            transform="translate(-462.14 472.22) rotate(-40.31)"/>
      <path className="cls-9"
            d="M663.93,514.61h7a0,0,0,0,1,0,0v61.17a0,0,0,0,1,0,0h-7a9.68,9.68,0,0,1-9.68-9.68V524.29A9.68,9.68,0,0,1,663.93,514.61Z"
            transform="translate(-517.15 430.43) rotate(-40.31)"/>
      <rect className="cls-10" x="645.11" y="387.46" width="183.35" height="183.35" rx="34.42" ry="34.42"
            transform="translate(-456.78 462.76) rotate(-40.31)"/>
      <path className="cls-9"
            d="M815.88,386.12h7a0,0,0,0,1,0,0v61.17a0,0,0,0,1,0,0h-7a9.68,9.68,0,0,1-9.68-9.68V395.8A9.68,9.68,0,0,1,815.88,386.12Z"
            transform="translate(1383.43 79.81) rotate(139.69)"/>
      <path className="cls-11"
            d="M660.7,406.62l-2.42,19s16.63-2.15,25.34-9.88c12.52-11.1,12.08-23.35,12.08-23.35l-17.8,1.46Z"
            transform="translate(-321.77 -127.68)"/>
      <circle className="cls-2" cx="339.42" cy="263.04" r="18.94"/>
      <circle className="cls-2" cx="361.26" cy="360.41" r="25.47"/>
      <circle className="cls-2" cx="432.22" cy="300.21" r="25.47"/>
      <circle className="cls-4" cx="361.26" cy="360.41" r="18.84"/>
      <circle className="cls-4" cx="432.22" cy="300.21" r="18.84"/>
      <polygon className="cls-1" points="407.74 381.01 402.8 338.44 443.23 350.89 407.74 381.01"/>
      <rect className="cls-4" x="711.77" y="501.16" width="112.53" height="29.6" rx="14.8" ry="14.8"
            transform="translate(-473.19 491.72) rotate(-40.31)"/>
      <rect className="cls-11" x="737.63" y="524.87" width="4.92" height="29.6"
            transform="translate(-495.16 479.28) rotate(-40.31)"/>
      <rect className="cls-11" x="752.49" y="512.25" width="4.92" height="29.6"
            transform="translate(-483.47 485.89) rotate(-40.31)"/>
      <rect className="cls-11" x="767.14" y="499.83" width="4.92" height="29.6"
            transform="translate(-471.95 492.42) rotate(-40.31)"/>
      <rect className="cls-11" x="781.14" y="487.95" width="4.92" height="29.6"
            transform="translate(-460.95 498.65) rotate(-40.31)"/>
      <rect className="cls-11" x="794.81" y="476.35" width="4.92" height="29.6"
            transform="translate(-450.19 504.75) rotate(-40.31)"/>
      <path className="cls-1"
            d="M658.92,509.74h7a0,0,0,0,1,0,0V570.9a0,0,0,0,1,0,0h-7a9.68,9.68,0,0,1-9.68-9.68V519.42a9.68,9.68,0,0,1,9.68-9.68Z"
            transform="translate(-515.18 426.04) rotate(-40.31)"/>
      <path className="cls-1"
            d="M811.09,380.6h7a0,0,0,0,1,0,0v61.17a0,0,0,0,1,0,0h-7a9.68,9.68,0,0,1-9.68-9.68V390.28A9.68,9.68,0,0,1,811.09,380.6Z"
            transform="translate(1371.42 73.18) rotate(139.69)"/>
    </svg>
  </div>
);
