import React, { Component, Fragment } from "react";
import posed, { PoseGroup } from "react-pose";
import { compose, withState } from "recompose";
import styled from 'styled-components';

import Container from "./components/Container";
import withTickets from "./withTickets";
import shuffle from "./utilities/shuffle";
import MainWrapper from "./components/MainWrapper";
import PrimaryButton from "./components/PrimaryButton";
import Confetti from "react-confetti";

const CustomMainWrapper = styled(MainWrapper)`
  grid-template-columns: auto;
`;

const CustomContainer = styled(Container)`
  grid-column: auto;
`;

const List = styled.div`
  font-size: 6em;
  font-family: inherit;
  line-height: 2;
`;

const Item = posed.div({
  preEnter: {
    y: -100,
  },
  enter: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 100,
  },
  transition: {
    type: 'spring',
    stiffness: props => props.duration,
    damping: props => props.duration / 10,
  },
});

class Roll extends Component {
  state = {
    item: null
  };

  componentDidMount() {
    this.mounted = true;

    const { sequence, roll } = this.props;

    const length = sequence.length;

    const DURATION = 4000;

    const step = remaining => {
      if (this.mounted) {
        const duration = DURATION / length / remaining;

        if (remaining > 0) {
          this.setState({
            item: [sequence[remaining - 1], duration],
          });
          setTimeout(() => {
            step(remaining - 1);
          }, duration / 2);
        } else {
          this.setState({
            item: [roll, duration],
          });
        }
      }
    };

    step(sequence.length);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { item } = this.state;

    return (
      <List style={{ paddingTop: '1em', paddingBottom: '1em' }}>
        <PoseGroup preEnterPose="preEnter">
          {item && <Item key={Math.random()} duration={item[1]}>{item[0]}</Item>}
        </PoseGroup>
      </List>
    );
  }
}

const Draw = ({ tickets, roll, setRoll }) => {
  const draw = () => {
    setRoll(shuffle(tickets));
  };

  return (
    <CustomMainWrapper>
      <CustomContainer>
        {roll && (
          <Fragment>
            <Roll key={roll[0]} sequence={roll.slice(1, 11)} roll={roll[0]} />
            <Confetti
              numberOfPieces='100'
              width={window.innerWidth / 2}
              height={window.innerHeight / 2}
              colors={['#151008', '#4b3e28', '#7c1200', '#e43213', '#e9b41a', '#a18227']}
              style={{position: 'absolute', width: '100%', height: '100%'}}
            />
          </Fragment>
        )}
        {tickets && (
          <PrimaryButton onClick={draw}>Draw</PrimaryButton>
        )}
      </CustomContainer>
    </CustomMainWrapper>
  );
};

export default compose(
  withTickets,
  withState('roll', 'setRoll', null),
)(Draw);
