import React, { Fragment } from 'react';
import { compose, withState } from 'recompose'
import styled from 'styled-components';
import posed, { PoseGroup } from 'react-pose';

import withQuestions from './withQuestions';

import Timer from './Timer';
import Question from './Question';
import Container from "./components/Container";
import heart from './assets/heart.svg';
import ticket from './assets/tickets.svg';
import Button from "./components/Button";
import Voucher from "./components/Voucher";

const CustomContainer = styled(Container)`
  display: grid;
  > * {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
  }
`;

const PosedQuestion = posed(Question)({
  preEnter: {
    opacity: 0,
    scale: 1.2,
  },
  enter: {
    opacity: 1,
    scale: 1,
    staggerChildren: 50,
    transition: {
      type: 'spring',
      stiffness: 120,
      damping: 20,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.8,
    staggerChildren: 25,
    transition: {
      type: 'spring',
      stiffness: 120,
      damping: 20,
    },
  },
});

const StyledVoucher = styled(Voucher)`
  pointer-events: none;
  z-index: 1000;
  position: relative;
  top: -13vh;
  width: 10vw;
  align-self: start;
  justify-self: center;
`;

const PosedVoucher = posed(StyledVoucher)({
  preEnter: {
    opacity: 0,
    scale: 4,
  },
  enter: {
    opacity: 1,
    scale: 1,
    transition: {
      type: 'spring',
      stiffness: 120,
      damping: 20,
    },
  },
  store: {
    opacity: 0,
    scale: 0.5,
    x: '-36vw',
    y: '-4vh',
    rotate: '500deg',
    transition: {
      type: 'spring',
      stiffness: 60,
      damping: 20,
    },
  },
});

const Life = styled.div`
  pointer-events: none;
  z-index: 2000;
  height: 2em;
  width: 2em;
  background: url(${heart}) center no-repeat;
  background-size: contain;
  margin-left: .5em;
  justify-items; center;
`;

const PosedLife = posed(({ hostRef, ...props }) => <Life ref={hostRef} {...props} />)({
  available: {

  },
  unavailable: {
    opacity: 0,
    x: '-32vw',
    y: '32vh',
    scale: 6,
    transition: {
      type: 'spring',
      stiffness: 120,
      damping: 20,
    },
  },
});

const Status = styled.div`
  display: flex;
  justify-content: space-between;
  grid-column: 1 / span 3;
  grid-rows: 1 / 1;
`;

const Tickets = styled.div`
  padding: 2em;
  font-weight: bold;
  display: grid;
  grid-template-columns: 6em auto;
  color: white;
`;

const NumberOfTickets = styled.span`
  font-size: 2em;
  background-color: #44d469;
  border-radius: 6em;
  height: 3em;
  width: 3em;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  box-shadow: 0 8px 0 0 rgba(0,0,0,0.15);
`;

const Ticket = styled.img`
  flex; 1 0 2em;
`;

const Lives = styled.div`
  padding: 2em;
  align-self: right;
  font-weight: bold;
  display: flex;
`;

const Footer = styled.footer`
  grid-row: 3 / 3;
  grid-column: 2 / 2;
`;

const MAX_LIVES = 3;

const Game = props => {
  const {
    ticketsEarned,
    setTicketsEarned,
    ticketsStored,
    setTicketsStored,
    livesAvailable,
    setLivesAvailable,
    question,
    onAnswer,
    onDone,
    onQuit,
  } = props;

  const takeLife = answers => {
    if (livesAvailable - 1 === 0) {
      onDone(answers);
    } else {
      setLivesAvailable(livesAvailable - 1);
    }
  };

  const handleAnswer = async answer => {
    const [correct, answers] = onAnswer(answer);

    if (correct) {
      setTicketsEarned(ticketsEarned + 1);
    } else {
      takeLife(answers);
    }
  };

  const handleExpire = async () => {
    const answers = onAnswer()[1];

    takeLife(answers);
  };

  const handleVoucherPoseComplete = e => {
    if (e === 'enter') {
      setTicketsStored(ticketsStored + 1);
    }
  };

  return (
    <Fragment>
      <Status>
        <Tickets>
          <Ticket src={ticket}/>
          <NumberOfTickets>{ticketsStored}</NumberOfTickets>
        </Tickets>
        <Lives>
          {[...Array(MAX_LIVES).keys()].map(i =>
            <PosedLife key={i} pose={(MAX_LIVES - i - 1) < livesAvailable ? 'available' : 'unavailable'} />
          )}
        </Lives>
      </Status>
      <CustomContainer>
        <PoseGroup preEnterPose="preEnter">
          {[...Array(ticketsEarned)].map((_, i) =>
            <PosedVoucher key={i} pose={i < ticketsStored ? 'store' : null} onPoseComplete={handleVoucherPoseComplete} />
          )}
        </PoseGroup>
        <PoseGroup preEnterPose="preEnter" animateOnMount={true}>
          {question && (
            <PosedQuestion key={question.id} {...question} onAnswer={handleAnswer}/>
          )}
        </PoseGroup>
      </CustomContainer>
      <Footer>
        <Timer key={question.id} time={16000} onExpire={handleExpire} />
      </Footer>
      <Button dull onClick={onQuit} style={{ padding: '0 0.4vw', position: 'fixed', bottom: 0, left: 0 }}>
        <span role="img" aria-label="Abort">☠️</span>
      </Button>
    </Fragment>
  );
};

export default compose(
  withQuestions,
  withState('ticketsEarned', 'setTicketsEarned', 0),
  withState('ticketsStored', 'setTicketsStored', 0),
  withState('livesAvailable', 'setLivesAvailable', MAX_LIVES),
)(Game);
