import styled from 'styled-components';

export default styled.div`
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 3px solid rgba(67, 182, 246, 1);
  border-radius: 50%;
  border-top-color: rgba(67, 182, 246, 0.2);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  
  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
`;
