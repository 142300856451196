import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  font-size: 2em;
  padding: 1.5rem 3rem;
  background-color: ${props => props.dull ? 'transparent' : 'white'};
  opacity: ${props => props.dull ? '0.2' : '1'};
  box-shadow: 0 8px 0 0 rgba(255, 255, 255, 0.15);
  outline: none;
  transition: all 0.1s ease;
  border: none;

  &:active {
    background-color: ${props => props.dull ? 'transparent' : 'ffd400'};
  }
`;

export default ({ hostRef, ...props }) => <Button {...props} ref={hostRef} />;
