import React, { Fragment } from 'react';
import { compose, withState } from "recompose";
import firebase from 'firebase';
import styled from 'styled-components';
import Confetti from 'react-confetti';
import format from 'date-fns/format';

import Container from "./components/Container";
import MainText from "./components/MainText";
import Button from "./components/Button";
import voucher from './assets/voucher.svg';
import sadFace from './assets/sad-face.svg';
import PhotoBooth from "./PhotoBooth";
import Spinner from "./components/Spinner";

const Input = styled.input`
  font-size: 2em;
  padding: 1.5rem;
  background-color: white;
  border: 2px solid black;
  border-radius: .25em;
`;

const Illustration = styled.img`
  font-size: 3em;
  height: 30vh;
`;

const ShareButton = styled.button`
  border: 0;
  padding: 1rem 1.5em;
  font-size: 2em;
  color: #44d469;
  border-radius: .25em;
  box-shadow: 2px 2px 0 rgba(0,0,0,.1);
  margin-bottom: 1em;
  outline: none;
  
  &:active {
    background-color: #44d469;
    color: white;
  }
`;

const Photo = styled.img`
  position: absolute;
  width: 22vw;
  object-fit: contain;
  transform: rotate(25deg);
  
  left: 55vw; 
  top: 12vh;
`;

const FieldGroup = styled.div`
  display: flex;
  margin-bottom: 1em;
  *:nth-child(even) {
    margin-left: 1em;
  }
  > input {
    flex: 0 1 100%;
  }
`;

const sanitizeTwitterHandle = (handle) => {
  if(!handle || handle.startsWith('@')) {
    return handle;
  } else {
    return `@${handle}`
  }
};

const Score = ({ email, setEmail, twitter, setTwitter, inProgress, setInProgress, answers, onDone, photoBooth, setPhotoBooth }) => {
  const hasBonus = !!photoBooth.photo;
  const tickets = answers.map(answer => answer.correct).filter(_ => _).length * (hasBonus ? 2 : 1);

  const handleSubmit = async () => {
    if(photoBooth.photo && (!twitter || twitter.length < 4)) {
      alert('Please give us your twitter handle so we can share your awesome score.');
      return;
    }

    if (email.match(/@/)) {

      try {
        setInProgress(true);

        const payload = {
          email,
          tickets,
          answers,
          date: format(new Date()),
          uid: (firebase.auth().currentUser || {}).uid || false,
        };

        if(photoBooth.photo) {
          const strippedData = photoBooth.photo.replace(/^data:image\/[a-z]+;base64,/, '');
          const response = await firebase.storage().ref(`${Date.now()}.jpeg`)
            .putString(strippedData, 'base64', {contentType: 'image/jpeg'});
          const photoUrl = await response.ref.getDownloadURL();

          payload['twitterHandle'] = sanitizeTwitterHandle(twitter);
          payload['photoUrl'] = photoUrl;
        }

        await firebase.firestore().collection('submissions').add(payload);

        onDone();
      } catch (e) {
        setInProgress(false);
        alert(e.message);
      }
    } else {
      alert('This does not look like an email address *at all*.');
    }
  };

  const onPhotoTaken = (photo) => setPhotoBooth({inProgress: false, photo: photo});

  return (
    <Container>
      {tickets === 0 ? (
        <Fragment>
          <Illustration src={sadFace} />
          <MainText>You didn’t win any ticket.</MainText>
          <Button onClick={onDone}>Accept It</Button>
        </Fragment>
      ) : (
        <Fragment>
          {photoBooth.inProgress && <PhotoBooth onDone={onPhotoTaken} tickets={tickets}/>}
          {photoBooth.photo && <Photo src={photoBooth.photo}/>}
          <Illustration src={voucher} />
          <MainText>{tickets === 1 ? 'You won a lottery ticket!' : `You won ${tickets} lottery tickets!`}</MainText>
          {!photoBooth.photo && <ShareButton onClick={() => setPhotoBooth({inProgress: true}) }>
            {tickets === 1 ? 'Share a photo and get 2 tickets instead of 1' : `Share a photo and double your lottery tickets!`}
            </ShareButton>}
          <Confetti
            numberOfPieces='200'
            wind='1'
            width={window.innerWidth / 2}
            height={window.innerHeight / 2}
            colors={['#151008', '#4b3e28', '#7c1200', '#e43213', '#e9b41a', '#a18227']}
            style={{position: 'absolute', width: '100%', height: '100%'}}
            recycle={false}
          />
          <FieldGroup>
            <Input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="e.g., ••••••@gmail.com" disabled={inProgress} />
            {photoBooth.photo && <Input type="text" value={twitter} onChange={e => setTwitter(e.target.value)} placeholder="e.g., @SpiceFctry" disabled={inProgress} />}
          </FieldGroup>
          <Button onClick={handleSubmit} disabled={inProgress}> {inProgress ? <Spinner/> : 'Submit'} </Button>
          <Button dull onClick={onDone}>Skip</Button>
        </Fragment>
      )}
    </Container>
  );
};

export default compose(
  withState('email', 'setEmail', ''),
  withState('twitter', 'setTwitter', ''),
  withState('inProgress', 'setInProgress', false),
  withState('photoBooth', 'setPhotoBooth', {inProgress: false, photo: null}),

)(Score);
