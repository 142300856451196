import React, { Component } from 'react';
import styled from 'styled-components';

const Bar = styled.div`
  background: #ff4800;
  border-radius: 3em;
  border: 0.33em solid #ff4800;
  height: 0;
`;

export default class Timer extends Component {
  static defaultProps = {
    time: 8000,
    onExpire: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      timeRemaining: props.time,
    };
  }

  componentDidMount() {
    this.mounted = true;

    const startTime = new Date().getTime();

    const step = () => {
      if (this.mounted && this.state.timeRemaining > 0) {
        window.requestAnimationFrame(() => {
          const currentTime = new Date().getTime();
          const timeRemaining = this.props.time - (currentTime - startTime);
          if (this.mounted) {
            this.setState({ timeRemaining }, step);
            if (timeRemaining <= 0) {
              this.props.onExpire();
            }
          }
        });
      }
    };

    step();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    return <Bar style={{ width: 100 * this.state.timeRemaining / this.props.time + '%' }} />
  }
}
