import React from 'react';
import { compose, withState } from 'recompose';
import styled, { keyframes } from 'styled-components';
import firebase from "firebase/app";

import FloydImage from './assets/home/Floyd.svg';
import IanImage from './assets/home/Ian.svg';
import SavaImage from './assets/home/Sava.svg';
import SpiceFactoryImage from './assets/home/SpiceFactory.svg';

import Game from './Game';
import Score from './Score';
import MainWrapper from "./components/MainWrapper";
import Container from "./components/Container";
import PrimaryButton from "./components/PrimaryButton";

const Floyd = styled.img.attrs({ src: FloydImage })`
  position: fixed;
  width: 16vw;
  right: -2vw;
  top: calc(50% - 8vw);
`;

const Ian = styled.img.attrs({ src: IanImage })`
  position: fixed;
  width: 32vw;
  left: -10vw;
  top: calc(50% - 15vw);
`;

const Sava = styled.img.attrs({ src: SavaImage })`
  position: fixed;
  width: 10vw;
  bottom: 2vw;
  left: calc(51.8% + 1vw);
`;

const SpiceFactory = styled.img.attrs({ src: SpiceFactoryImage })`
  position: fixed;
  width: 14vw;
  bottom: 2vw;
  right: calc(48.2% + 1vw);
`;

const Application = props => {
  const {
    session,
    setSession,
    gameInProgress,
    setGameInProgress,
    questions,
    setQuestions,
    answers,
    setAnswers,
  } = props;

  if (!session.loggedIn && !session.loggingIn) {
    const logIn = async () => {
      setSession({ loggedIn: false, loggingIn: true });
      try {
        const result = await firebase.auth().signInAnonymously();
        setSession({ loggedIn: result.user.uid, loggingIn: false });
      } catch (e) {
        setSession({ loggedIn: false, loggingIn: false });
      }
    };

    logIn();
  }

  const handleStart = async () => {
    setGameInProgress(true);

    try {
      const questionsSnapshot = await firebase.firestore().collection('questions').get();
      const questions = questionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setQuestions(questions);
    } catch (e) {
      setGameInProgress(false);
      alert(e.message);
    }
  };

  const reset = () => {
    setGameInProgress(false);
    setQuestions(null);
    setAnswers(null);
  };

  return (
    <MainWrapper uid={session.loggedIn} style={{ background: gameInProgress && questions ? '#f8f9fb' : '#ffbc00' }}>
      {session.loggedIn && (
        gameInProgress && questions ? (
          answers ? (
            <Score answers={answers} onDone={reset} />
          ) : (
            <Game questions={questions} onDone={setAnswers} onQuit={reset} />
          )
        ) : (
          <Container>
            <Floyd />
            <Ian />
            <Sava />
            <SpiceFactory />
            <h1 style={{ fontSize: '4vw', fontWeight: 800, color: 'black' }}>Hey! Want to win a<br />Robot Builder?</h1>
            <PrimaryButton disabled={gameInProgress} onClick={handleStart}>Start Game</PrimaryButton>
          </Container>
        )
      )}
    </MainWrapper>
  );
};

export default compose(
  withState('session', 'setSession', { loggedIn: false, loggingIn: false }),
  withState('gameInProgress', 'setGameInProgress', false),
  withState('questions', 'setQuestions', null),
  withState('answers', 'setAnswers', null),
)(Application);
