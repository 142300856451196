import React from 'react';
import shuffle from "./utilities/shuffle";

export default Component => class QuestionsProvider extends React.Component {
  constructor(props) {
    super(props);

    const questions = props.questions.slice();
    const index = Math.floor(Math.random() * questions.length);
    const question = questions.splice(index, 1)[0];

    this.state = {
      questions,
      question,
      answers: [],
    };
  }

  pickQuestion = () => {
    const questions = this.state.questions.slice();
    const index = Math.floor(Math.random() * questions.length);
    const question = questions.splice(index, 1)[0];

    this.setState({ questions, question });
  };

  handleAnswer = answer => {
    const correct = answer === this.state.question.correctAnswer;
    const answers = this.state.answers.slice();
    answers.push({
      question: this.state.question,
      answer: answer,
      correct,
    });

    this.setState({ answers });

    this.pickQuestion();

    return [correct, answers];
  };

  render() {
    const { props, state: { questions, question: firestoreQuestion } } = this;

    if (!questions || !firestoreQuestion) {
      return null;
    }

    const question = {
      id: firestoreQuestion.id,
      text: firestoreQuestion.question,
      answers: shuffle([
        firestoreQuestion.correctAnswer,
        firestoreQuestion.wrongAnswer1,
        firestoreQuestion.wrongAnswer2,
        firestoreQuestion.wrongAnswer3,
      ]),
    };

    return (
      <Component {...props} question={question} onAnswer={this.handleAnswer} />
    );
  }
};
