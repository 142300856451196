import React from 'react';
import firebase from "firebase/app";

export default Component => class QuestionsProvider extends React.Component {
  state = {
    tickets: null,
  };

  async componentDidMount() {
    const submissionsSnapshot = await firebase.firestore().collection('submissions').get();
    const submissions = submissionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    this.setState({
      tickets: submissions
        .reduce(
          (tickets, submission) =>
            [...tickets, ...[...Array(submission.tickets)].map(_ => submission.email)],
          []
        )
    });
  }

  render() {
    const { props, state: { tickets } } = this;

    return (
      <Component {...props} tickets={tickets} />
    );
  }
};
