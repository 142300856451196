import styled from 'styled-components';

const Container = styled.div`
  grid-row: 2/2;
  grid-column: 2/2;
  justify-self: center;
  align-self: center;
  text-align: center;
`;

export default Container;
