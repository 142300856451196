import React from 'react';
import Webcam  from 'react-webcam';
import 'react-html5-camera-photo/build/css/index.css';
import styled from 'styled-components';
import {withProps} from "recompose";
import overlay from './assets/overlay.png';
import Button from "./components/Button";

const CameraPreview = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  
  > div {
    display: block;
    width: 100%;
    > video {
      width: 100%;
    }
  }
`;

const Overlay = styled.img`
  position: absolute;
  object-fit: contain;
  width: 100%;
`;

const ScoreLabel = styled.label`
  position: absolute;
  padding-left: 68vw;
  padding-top: 44vh;
  font-size: 10vw;
  font-family: Courier;
  font-weight: 800;
`;

class PhotoBooth extends React.PureComponent {
  createImage = (src, onLoad) => {
    const img = new Image();
    img.onload = () => {
      onLoad(img);
    };

    img.src = src;
  };

  setRef = webcam => {
    this.webcam = webcam;
  };

  takePhoto = () => {
    const photo = this.webcam.getScreenshot();
    console.log(photo);

    const tickets = `${this.props.tickets}`;

    // TODO: Convert to promise api
    this.createImage(photo, (img) => {
      const width = img.width;
      const height = img.height;

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext("2d");

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, width, height);

      this.createImage(overlay, (layer) => {

        ctx.drawImage(layer, 0, 0, width, height);

        ctx.font = `bold ${width * 0.1}px Courier`;
        ctx.fillText(tickets, width * (0.83 - tickets.length * 0.02),height * 0.78);

        const data = canvas.toDataURL('image/jpeg', 0.95);

        if(this.props.onDone) {
          this.props.onDone(data);
        } else {
          // show image in a new tab
          this.createImage(data, (resultImage) => {
            const w = window.open("");
            w.document.write(resultImage.outerHTML);
          });
        }

      });
    });

  };

  render () {
    const {tickets} = this.props;
    return (
        <CameraPreview >
          <Webcam
            audio={false}
            height={'100%'}
            width={'100%'}
            ref={this.setRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              width: 3000,
              height: 1687,
              facingMode: "user"
            }}
          />

          <Overlay src={overlay} />
          <ScoreLabel>{tickets}</ScoreLabel>
          <Button onClick={this.takePhoto} style={{position: 'absolute', bottom: '10vh', zIndex: 9999}}>Capture</Button>
        </CameraPreview>
    )};
  }


export default withProps(
  props => ({tickets: props.tickets * 2|| 14})
)(PhotoBooth);
