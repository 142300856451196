import styled from 'styled-components';

const MainWrapper = styled.div`
  display: grid;
  background-color: #f8f9fb;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  grid-template-rows: [header] 4em [body] auto [footer] 4em [end];
  grid-template-columns: auto 50% auto;
  color: #1e2a45;
  transition: all 200ms ease-in-out;
  
  ::after {
    content: '${props => props.uid}';
    position: fixed;
    bottom: 0;
    right: 0;
    opacity: 0.1;
    padding: 0.2em 0.4em;
  }
`;

export default MainWrapper;
