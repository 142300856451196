import React from 'react';
import styled from 'styled-components';
import posed from "react-pose";

import MainText from "./components/MainText";
import Button from "./components/Button";
import Container from "./components/Container";

const Answers = styled.div`
  display: grid;
  grid-template-rows: repeat(4, auto);
  grid-row-gap: .5em;
`;

const PosedButton = posed(Button)({
  preEnter: {
    x: 100,
  },
  enter: {
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 220,
      damping: 20,
    },
  },
  exit: {
    x: -200,
    transition: {
      type: 'spring',
      stiffness: 220,
      damping: 20,
    },
  }
});

export default ({ hostRef, text, answers, onAnswer }) => (
  <Container ref={hostRef}>
    <MainText>{text}</MainText>
    <Answers>
    {answers.map((text, i) =>
      <PosedButton key={i} onClick={() => onAnswer(text)}>{text}</PosedButton>
    )}
    </Answers>
  </Container>
);
