import { keyframes } from "styled-components";
import styled from "styled-components";
import Button from "./Button";

const bounce = keyframes`
  0% {-webkit-transform: scale(1.5);}
  100% {-webkit-transform: scale(1.55);}
`;

const bounceBig = keyframes`
  0% {-webkit-transform: scale(1.7);}
  100% {-webkit-transform: scale(1.75);}
`;

const PrimaryButton = styled(Button)`
  margin-top: 2em;
  background-color: #ff4800;
  color: white;
  font-weight: 800;
  text-transform: uppercase;
  animation: ${bounce} .3s ease-in-out infinite;
  animation-direction: alternate;
  transition: all .1s ease;
  
  &:active {
    background-color: #ff4800;
    animation: ${bounceBig} .15s ease-in-out infinite;
    animation-direction: alternate;
  }
`;

export default PrimaryButton;
